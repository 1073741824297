<template>
  <div>
    <v-card>
      <v-card-title>BUSCADOR</v-card-title>
      <v-card-text>
        <v-row>
          <v-col class="d-flex flex-row">
            <v-text-field
              v-model="filters.search"
              clearable
              placeholder="Busca por nome da instituição"
            ></v-text-field>
            <v-btn
              class="mx-2 my-3"
              color="primary"
              @click="loadInstitutions()"
            >
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
            <v-btn @click="clearFilters()" class="mx-2 my-3">Limpar</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header> BUSCADOR </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col class="d-flex flex-row">
              <v-text-field
                v-model="filters.search"
                clearable
                placeholder="Busca por nome da instituição"
              ></v-text-field>
              <v-btn
                class="mx-2 my-3"
                color="primary"
                @click="loadInstitutions()"
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
              <v-btn @click="clearFilters()" class="mx-2 my-3">Limpar</v-btn>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels> -->

    <v-card class="mt-3">
      <v-card-title>Administração de instituições</v-card-title>

      <v-card-text>
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="institutions"
          :single-expand="singleExpand"
          :expanded.sync="expanded"
          disable-pagination
          hide-default-footer
          show-expand
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-spacer></v-spacer>
              <v-btn
                rounded
                small
                color="success"
                @click="$router.push('/admin/instituicoes/cadastrar')"
                >Cadastrar +</v-btn
              >
            </v-toolbar>
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-row class="pt-2 pb-2">
                <v-col sm="12">
                  <v-btn
                    class="mr-2"
                    title="Editar dados"
                    @click="editInstitution(item.id)"
                    small
                    text
                  >
                    <v-icon left>mdi-pencil</v-icon> Editar dados
                  </v-btn>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row class="pt-2 pb-2">
                <v-col sm="12">
                  <v-btn
                    class="mr-2"
                    title="Administrar usuários"
                    @click="redirectToLinkedUsers(item.id)"
                    small
                    text
                  >
                    <v-icon left>mdi-account-group</v-icon> Administrar usuários
                  </v-btn>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row class="pt-2 pb-2">
                <v-col sm="12">
                  <v-btn
                    class="mr-2"
                    @click="redirectToTeacherPendingRequests(item.id)"
                    title="Solicitações pendentes (de professores)"
                    small
                    text
                  >
                    <v-icon left>mdi-account-check</v-icon> Solicitações
                    pendentes (professores)
                  </v-btn>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row class="pt-2 pb-2">
                <v-col sm="12">
                  <v-btn
                    class="mr-2"
                    title="Administrar salas"
                    @click="redirectToAdminRooms(item.id)"
                    small
                    text
                  >
                    <v-icon left>mdi-google-classroom</v-icon> Administrar salas
                  </v-btn>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row class="pt-2 pb-2">
                <v-col sm="12">
                  <v-btn
                    class="mr-2"
                    title="Deletar"
                    @click="deleteInstitution(item.id)"
                    small
                    text
                  >
                    <v-icon left>mdi-delete</v-icon> Deletar
                  </v-btn>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </td>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <div class="text-center mt-2">
      <v-pagination
        v-model="page"
        :length="numberOfPages"
        @input="loadInstitutions"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import errorHandler from "@/helpers/error_handler";

export default {
  computed: {
    numberOfPages() {
      return Math.ceil(this.totalRows / this.resultsPerPage);
    },
  },
  data: () => ({
    loading: false,
    resultsPerPage: 15,
    singleExpand: true,
    totalRows: 0,
    page: 1,
    headers: [
      { text: "Nome", value: "institution_name" },
      { text: "Cidade", value: "city_address" },
      { text: "Estado", value: "state_address" },
      { text: "Ações", value: "data-table-expand" },
    ],
    institutions: [],
    expanded: [],
    filters: {
      currentPage: 1,
      search: null,
    },
  }),
  methods: {
    async loadInstitutions(page) {
      try {
        this.loading = true;

        let currentPage = page ? page : 1;

        const response = await this.$axios.get(`/admin/instituicoes`, {
          params: {
            search: this.filters.search,
            pagination: 1,
            resultsPerPage: this.resultsPerPage,
            page: currentPage,
          },
        });

        this.setInstitutions(response.data);
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    setInstitutions(data) {
      const cpData = { ...data };

      this.totalRows = cpData.count;

      this.institutions = cpData.rows.map((u) => ({
        // fullName: `${u.firstName} ${u.lastName}`,
        ...u,
      }));
    },
    async deleteInstitution(id) {
      try {
        const confirm = await this.$root.$confirm(
          "Atenção",
          `Deseja realmente deletar esta instituição?`
        );

        if (!confirm) return;

        this.loading = true;

        let url = `/admin/instituicoes/${id}/deletar`;

        await this.$axios.delete(url);

        this.deleteInstitutionFromTable(id);
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    deleteInstitutionFromTable(id) {
      const index = this.institutions.findIndex((p) => p.id === id);

      this.institutions.splice(index, 1);
    },
    editInstitution(id) {
      this.$router.push(`/admin/instituicoes/editar/${id}`);
    },
    redirectToLinkedUsers(id) {
      this.$router.push(`/admin/instituicao/${id}/usuarios`);
    },
    redirectToTeacherPendingRequests(id) {
      this.$router.push(`/admin/solicitacoes/buscar/${id}`);
    },
    redirectToAdminRooms(id) {
      this.$router.push(`/admin/instituicoes/${id}/salas/buscar`);
    },
    clearFilters() {
      this.filters = {
        currentPage: 1,
        search: null,
      };

      this.loadInstitutions();
    },
    handleErrors(errors) {
      console.error(errors);

      const errorHandled = errorHandler.treatError(errors);

      if (Array.isArray(errorHandled))
        errorHandled.map((e) => this.$toast.error(e));
      else this.$toast.error(errorHandled);
    },
  },
  created() {
    this.loadInstitutions();
  },
};
</script>

<style></style>
